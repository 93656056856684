import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import LecturesList from '../components_/LecturesList'
import Lecture from '../components_/Lecture'

const Lectures = () => {
    const { topicId } = useParams()
    return (
        <Routes>
            <Route path="/lectures" element={< LecturesList topic={topicId} />} />
            <Route path="/lectures/:lectureId" element={< Lecture />} />
        </Routes>
    )
}

export default Lectures
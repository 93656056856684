import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Box, Card, CardContent, CardActions, Fab, Typography, ButtonGroup, Button, CircularProgress as Loader, Modal, TextField } from '@mui/material'
import { Delete, Add, Create, Check } from "@mui/icons-material"

import { disableUser, enableUser, getAllUsers, newUser } from '../../controllers/users';

const AllUsers = () => {
    const nav = useNavigate();
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    const [newUserModalOpen, setNewUserModalOpen] = useState(false);
    const openNewUserModal = () => setNewUserModalOpen(true)
    const closeNewUserModal = () => setNewUserModalOpen(false)

    useEffect(() => {
        getAllUsers(setUsers, setLoading)
    }, [])

    return loading ? <Loader /> :
        <>
            {users.length === 0 ?
                <Typography variant="body2">No users available. Click on the below button to create a user.</Typography>
                :
                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                    {users.map(user => <UserChip key={user._id} {...{ user, nav, setUsers }} />)}
                </Box>}
            <Fab variant="extended" sx={{ position: 'fixed', right: 20, bottom: 20 }} color='success'
                onClick={openNewUserModal}
            >
                <Add sx={{ mr: 1 }} />
                New User
            </Fab>
            <NewUserModal {...{ open: newUserModalOpen, handleClose: closeNewUserModal, setUsers }} />
        </>
}

const UserChip = ({ user, nav, setUsers }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const openDeleteModal = () => setDeleteModalOpen(true)
    const closeDeleteModal = () => setDeleteModalOpen(false)

    return <Card sx={{ width: "80%", maxWidth: 390, marginY: 2, borderLeft: `solid 3px ${user.active ? "green" : "red"}` }}>
        <CardContent>
            <Typography variant="h5" component="div">
                {user.name}
            </Typography>
            <Typography gutterBottom variant="body2" component="div">
                {user.email}
            </Typography>
        </CardContent>
        <CardActions>
            <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                <ButtonGroup variant="contained">
                    <Button size="small" endIcon={user.active ? <Delete /> : <Check />} color={user.active ? 'error' : 'success'}
                        onClick={openDeleteModal}
                    >{user.active ? "De-activate" : "Activate"}</Button>
                </ButtonGroup>
                <Button size="small" variant="outlined" endIcon={<Create />} onClick={() => nav(`${user._id}/`)}>Edit Available Courses</Button>
            </Box>
        </CardActions>

        {user.active ?
            <DisableUserModal {...{ user, setUsers }} open={deleteModalOpen} handleClose={closeDeleteModal} />
            : <EnableUserModal {...{ user, setUsers }} open={deleteModalOpen} handleClose={closeDeleteModal} />
        }
    </Card>
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    outline: 0
};

const DisableUserModal = ({ user, open, handleClose, setUsers }) => {
    const [inName, setInName] = useState("")
    const [isDeleting, setIsDeleting] = useState(false)

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
                You are about to de-activating this user account!
            </Typography>
            <Typography sx={{ mt: 2 }}>
                To confirm de-activation, please enter the name "{user.name}" below.
            </Typography>
            <TextField
                placeholder={user.name}
                variant="outlined"
                size="small"
                label="Name"
                margin="dense"
                fullWidth
                sx={{ marginY: 2 }}
                value={inName}
                onChange={(e) => setInName(e.target.value)}
                error={inName !== user.name}
                disabled={isDeleting}
            />
            <ButtonGroup fullWidth>
                <Button variant="contained" onClick={handleClose} >Cancel</Button>
                <Button variant="contained"
                    color="error"
                    endIcon={<Delete />}
                    disabled={(inName !== user.name) || isDeleting}
                    onClick={() => {
                        disableUser(user._id, user.name, setIsDeleting, handleClose, setUsers)
                    }}
                >
                    {isDeleting ? <Loader /> : "De-activate"}
                </Button>
            </ButtonGroup>
        </Box>
    </Modal>
}

const EnableUserModal = ({ user, open, handleClose, setUsers }) => {
    const [inName, setInName] = useState("")
    const [isDeleting, setIsDeleting] = useState(false)

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
                You are about to activate this user account!
            </Typography>
            <Typography sx={{ mt: 2 }}>
                To confirm activation, please enter the name "{user.name}" below.
            </Typography>
            <TextField
                placeholder={user.name}
                variant="outlined"
                size="small"
                label="Name"
                margin="dense"
                fullWidth
                sx={{ marginY: 2 }}
                value={inName}
                onChange={(e) => setInName(e.target.value)}
                error={inName !== user.name}
                disabled={isDeleting}
            />
            <ButtonGroup fullWidth>
                <Button variant="contained" onClick={handleClose} >Cancel</Button>
                <Button variant="contained"
                    color="success"
                    endIcon={<Check />}
                    disabled={(inName !== user.name) || isDeleting}
                    onClick={() => {
                        enableUser(user._id, user.name, setIsDeleting, handleClose, setUsers)
                    }}
                >
                    {isDeleting ? <Loader /> : "Activate"}
                </Button>
            </ButtonGroup>
        </Box>
    </Modal>
}

const NewUserModal = ({ open, handleClose, nav }) => {
    const [data, setData] = useState({
        name: "",
        phone: "",
        email: ""
    })
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleSubmit = () => {
        newUser(data, setLoading, handleClose, nav)
    }

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={modalStyle}>
            {
                [
                    { label: "Full Name", name: "name" },
                    { label: "Email", name: "email" },
                    { label: "Phone number", name: "phone" }
                ].map(field => {
                    return <TextField
                        value={data[field.name]}
                        onChange={handleChange}
                        name={field.name}
                        label={field.label}
                        margin='normal'
                        fullWidth
                    />
                })
            }
            <Button variant="contained" fullWidth onClick={handleSubmit} disabled={!data.email.length || !data.name.length || !data.phone.length || loading}>{loading ? <Loader /> : "Create User Account"}</Button>
        </Box>
    </Modal>
}

export default AllUsers
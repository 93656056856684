import axios from "axios";
// eslint-disable-next-line

import Cookies from "js-cookie";

import { HOST } from "./constants";

const login = async ({ email, password, nav }) => {
  axios
    .post(HOST + "/auth/admin/login", { email, password })
    .then(({ data }) => {
      Cookies.set("accessToken", data.accessToken, {
        expires: 100,
      });
      nav("/home");
    })
    .catch((err) => {
      window.alert(JSON.stringify(err), HOST + "/auth/admin/login");
    });
};

const validateToken = async (setLoading, nav) => {
  axios
    .get(HOST + "/auth/validate", {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    })
    // .then((res) => nav("/home"))
    .catch((err) => nav("/login"))
    .finally(() => setLoading(false));
};

const handleLogout = (nav) => {
  Cookies.remove('accessToken')
  nav("/login");
}


export { login, validateToken, handleLogout };

import { useState, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'

import { CircularProgress as Loader } from "@mui/material";

import Login from '../components_/Login'
import AdminHome from './AdminHome'


import { validateToken } from "../controllers/auth";

const Index = () => {
    const nav = useNavigate();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        validateToken(setLoading, nav);
    }, [nav]);

    return loading ?
        <Loader /> : (
            <>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/home/*' element={<AdminHome />} />
                    <Route path='/*' element={<Navigate to="/home" />} />
                </Routes>
            </>
        )
}

export default Index
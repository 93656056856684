import Plyr from "plyr-react"
import "plyr-react/plyr.css"


function MyPlyrVideo({ source, name }) {
    return <Plyr source={{
        type: 'video',
        title: name,
        sources: [
            {
                src: source
            }]
    }} options={{
        autoplay: true,
        controls: ['play-large', 'play', 'progress', 'current-time', 'settings', 'airplay', 'fullscreen'],
        speed: { selected: 1, options: [0.8, 0.9, 1, 1.1, 1.25] },
        storage: { enabled: true, key: 'plyr' },
        clickToPlay: true
    }}
    />
}

export default MyPlyrVideo
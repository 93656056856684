import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { CircularProgress as Loader, Box, ButtonGroup, } from "@mui/material";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import { getUserById, updateCourses } from '../../controllers/users'
import { Add, Delete } from '@mui/icons-material';


const User = () => {
    const { userId } = useParams()
    const nav = useNavigate();

    const [user, setUser] = useState({})
    const [userCourses, setUserCourses] = useState([])
    const [allCourses, setAllCourses] = useState([])

    const [tracker, setTracker] = useState([])

    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        getUserById(userId, setUser, setUserCourses, setAllCourses, setLoading)
    }, [])

    useEffect(() => {
        setTracker(allCourses.map(course => {
            console.log({ ...course, activeForUser: userCourses.includes(course._id) })
            return { ...course, activeForUser: userCourses.includes(course._id) }
        }))
    }, [loading])

    return loading ? <Loader /> :
        <>
            <Typography sx={{ marginX: 3, marginTop: 2 }} variant="h6">{"> " + user.name}</Typography>
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                {tracker.map(course => <><CourseChip key={course._id} course={course} setCourses={setTracker} /></>)}
            </Box>
            <Button
                sx={{ position: 'fixed', bottom: 0 }}
                color="info"
                variant="contained"
                fullWidth
                disabled={submitting}
                onClick={() => !submitting && updateCourses(user._id, tracker, nav, setSubmitting)}
            >
                {submitting ? <Loader /> :
                    "Save Changes"
                }
            </Button>
            <Button sx={{ bottom: 0 }} color="info" fullWidth>.</Button>
        </>
}

const CourseChip = ({ course, setCourses }) => {
    const removeCourse = () => {
        setCourses(prev => {
            return prev.map(prevC => {
                if (prevC._id === course._id)
                    return { ...prevC, activeForUser: false }
                return prevC
            })
        })
    }

    const addCourse = () => {
        setCourses(prev => {
            return prev.map(prevC => {
                if (prevC._id === course._id)
                    return { ...prevC, activeForUser: true }
                return prevC
            })
        })
    }

    return <Card sx={{ width: "80%", maxWidth: 390, marginY: 2, borderLeft: `solid 2px ${course.activeForUser ? "green" : "red"}` }}>
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {course.name}
            </Typography>
        </CardContent>
        <CardActions>
            {course.activeForUser ?
                <Button onClick={removeCourse} variant="contained" size="small" color="warning" fullWidth startIcon={<Delete />} >Remove</Button> :
                <Button onClick={addCourse} variant="contained" size="small" color="success" fullWidth startIcon={<Add />} >Allow</Button>}
        </CardActions>
    </Card>
}

export default User
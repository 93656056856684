import { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';

import { CircularProgress as Loader } from "@mui/material";

import { Box, ButtonGroup, TextField } from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import DoubleArrowOutlined from '@mui/icons-material/DoubleArrowOutlined';

import { useNavigate } from 'react-router-dom';

import { getAllLectures, deleteLecture, addLecture, addLectureDrive } from '../controllers/education';

const LecturesList = ({ topic }) => {
    const nav = useNavigate()
    const [lectures, setLectures] = useState([])
    const [loading, setLoading] = useState(true);

    const [newLectureModalOpen, setNewLectureModalOpen] = useState(false);
    const openNewLectureModal = () => setNewLectureModalOpen(true)
    const closeNewLectureModal = () => setNewLectureModalOpen(false)

    useEffect(() => {
        getAllLectures(topic, setLectures, setLoading)
    }, [topic])
    return loading ? <Loader /> : <>
        {lectures.length === 0 ?
            <Typography variant="body2">No lectures in this course yet. Click on the below button to add a lecture.</Typography>
            :
            <Box display="flex" flexDirection="column" alignItems="center" width="100%"> {lectures.map(lecture => <LecturesListChip key={lecture._id} lecture={lecture} nav={nav} />)}</Box>
        }
        <Fab variant="extended" sx={{ position: 'fixed', right: 20, bottom: 20 }} color='success' onClick={openNewLectureModal}>
            <Add sx={{ mr: 1 }} />
            New Lecture
        </Fab>
        <NewLectureModal {...{ topic, open: newLectureModalOpen, handleClose: closeNewLectureModal, nav }} />
    </>
}

const LecturesListChip = ({ lecture, nav }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const openDeleteModal = () => setDeleteModalOpen(true)
    const closeDeleteModal = () => setDeleteModalOpen(false)

    return <>
        <Card sx={{ width: "80%", maxWidth: 390, marginY: 2 }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {lecture.name}
                </Typography>
            </CardContent>
            <CardActions>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                    <ButtonGroup variant="contained">
                        {/* <Button size="small" startIcon={<Create />} >Edit</Button> */}
                        <Button size="small" endIcon={<Delete />} color='error' onClick={openDeleteModal}>Delete</Button>
                    </ButtonGroup>
                    <Button size="small" variant="outlined" endIcon={<DoubleArrowOutlined />} onClick={() => nav(`${lecture._id}/`)}>View Lecture</Button>
                </Box>
            </CardActions>
        </Card>
        <DeleteLectureModal {...{ lecture, open: deleteModalOpen, handleClose: closeDeleteModal, nav }} />
    </>
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    outline: 0
};

const DeleteLectureModal = ({ lecture, open, handleClose, nav }) => {
    const [inName, setInName] = useState("")
    const [isDeleting, setIsDeleting] = useState(false)
    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
                You are about to delete the lecture!
            </Typography>
            <Typography sx={{ mt: 2 }}>
                To confirm deleting, please enter the lecture name "{lecture.name}" below.
            </Typography>
            <TextField
                placeholder={lecture.name}
                variant="outlined"
                size="small"
                label="Lecture Name"
                margin="dense"
                fullWidth
                sx={{ marginY: 2 }}
                value={inName}
                onChange={(e) => setInName(e.target.value)}
                error={inName !== lecture.name}
                disabled={isDeleting}
            />
            <ButtonGroup fullWidth>
                <Button variant="contained" onClick={handleClose} >Cancel</Button>
                <Button variant="contained"
                    color="error"
                    endIcon={<Delete />}
                    disabled={(inName !== lecture.name) || isDeleting}
                    onClick={() => {
                        deleteLecture(lecture._id, setIsDeleting, handleClose, nav)
                    }}
                >
                    {isDeleting ? <Loader /> : "Delete"}
                </Button>
            </ButtonGroup>
        </Box>
    </Modal>
}

const NewLectureModal = ({ topic, open, handleClose, nav }) => {
    const [lectureName, setLectureName] = useState("")
    const [lectureVideo, setLectureVideo] = useState("")
    const [lectureNotes, setLectureNotes] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        lectureName.length && lectureVideo && lectureNotes && addLectureDrive(topic, lectureName, lectureVideo, lectureNotes, handleClose, nav, setLoading)
    }

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={modalStyle}>
            <TextField
                value={lectureName}
                onChange={(e) => setLectureName(e.target.value)}
                label="Lecture Name"
                margin='dense'
                fullWidth
            />
            <TextField
                value={lectureVideo}
                onChange={(e) => setLectureVideo(e.target.value)}
                label="Lecture Video"
                margin='dense'
                fullWidth
            />
            <TextField
                value={lectureNotes}
                onChange={(e) => setLectureNotes(e.target.value)}
                label="Lecture Notes"
                margin='dense'
                fullWidth
            />
            <Button variant="contained" fullWidth onClick={handleSubmit} disabled={!(!!lectureName.length && !!lectureVideo.length && !!lectureNotes.length) || loading}>{loading ? <Loader /> : "Add Lecture"}</Button>
        </Box>
    </Modal>
}

export default LecturesList
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, CircularProgress as Loader, Typography } from '@mui/material'

import { getLectureById } from '../controllers/education';
import MyPlyrVideo from './MyPlyrVideo';

const Lecture = () => {
    const { lectureId } = useParams();
    const [lecture, setLecture] = useState(null)
    useEffect(() => {
        getLectureById(lectureId, setLecture)
    }, [lectureId])

    return (
        <Box display="flex" flexDirection="column" alignItems="center" paddingX={2} height="600px">
            {lecture ?
                <Box width="100vw">
                    <Typography paddingX={2} variant="h6">{`> ${lecture.name}`}</Typography>
                    <MyPlyrVideo source={lecture.video} name={lecture.name} />
                    {/* <iframe title='Notes PDF' src={`${lecture.notes}`} width={"100%"} height={"100%"} /> */}
                    <img src={`${lecture.notes}`} style={{ width: "100vw" }} />
                </Box>
                :
                <Loader />
            }
        </Box>
    )
}

export default Lecture
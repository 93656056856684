import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import {
  IonIcon,
  useIonViewWillEnter,
} from "@ionic/react";
import { login } from "../controllers/auth";
import "./Login.css";

import LetterSeen from "../images/markaz_logo-cropped.svg";

import { arrowForwardOutline } from "ionicons/icons";
import { useNavigate } from "react-router";
const Login = () => {
  const nav = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loginStep, setLoginStep] = useState("input-invalid");

  useIonViewWillEnter(() => {
    console.log("UseEffect");
  });

  useEffect(() => {
    const validateCredentials = () => {
      var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      var passFormat = /^[a-zA-Z0-9!@#$%^&*]{8,}$/;

      if (!credentials.email.match(mailformat))
        return setLoginStep("input-invalid");
      if (!credentials.password.match(passFormat))
        return setLoginStep("input-invalid");
      setLoginStep("input-valid");
    };
    validateCredentials();
  }, [credentials]);

  const handleCredentialsChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleLogin = () => {
    (loginStep === "input-valid") && login({ ...credentials, nav });
  }

  return (
    <div className="main-page-holder">
      <div className="logo-login-page">
        <img src={LetterSeen} alt="" className="logo-image" />
      </div>
      <div className="login-container">
        <div className="input-fields-container">
          <TextField
            name="email"
            value={credentials.email}
            onChange={handleCredentialsChange}
            className="input-field"
            margin="normal"
            label="Email"
            fullWidth
          />
          <TextField
            name="password"
            value={credentials.password}
            onChange={handleCredentialsChange}
            className="input-field"
            margin="normal"
            label="Password"
            type="password"
            fullWidth
          />
        </div>
        <div
          className={`login-button-out ${loginStep === "input-valid" ? "active" : "inactive"}`}
          onClick={handleLogin}
        >
          <div
            className={`login-button`}
          >
            <div className="login-button-text">Log In</div>
            <div className="login-button-arrow">
              <IonIcon icon={arrowForwardOutline} size="large"></IonIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
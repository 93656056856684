import { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';

import { CircularProgress as Loader } from "@mui/material";

import { Box, ButtonGroup, TextField } from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import DoubleArrowOutlined from '@mui/icons-material/DoubleArrowOutlined';

import { useNavigate } from 'react-router-dom';

import { getAllTopics, deleteTopic, addTopic } from '../controllers/education';

const TopicsList = ({ course }) => {
    const nav = useNavigate();
    const [topics, setTopics] = useState([])
    const [loading, setLoading] = useState(true);

    const [newTopicModalOpen, setNewTopicModalOpen] = useState(false);
    const openNewTopicModal = () => setNewTopicModalOpen(true)
    const closeNewTopicModal = () => setNewTopicModalOpen(false)

    useEffect(() => {
        getAllTopics(course, setTopics, setLoading)
    }, [course])
    return loading ? <Loader /> :
        <>
            {topics.length === 0 ?
                <Typography variant="body2">No topic in this course yet. Click on the below button to add a topic.</Typography>
                :
                <Box display="flex" flexDirection="column" alignItems="center" width="100%"> {topics.map(topic => <TopicsListChip key={topic._id} topic={topic} nav={nav} />)}</Box>}
            <Fab variant="extended" sx={{ position: 'fixed', right: 20, bottom: 20 }} color='success' onClick={openNewTopicModal}>
                <Add sx={{ mr: 1 }} />
                New Topic
            </Fab>
            <NewTopicModal {...{ course, open: newTopicModalOpen, handleClose: closeNewTopicModal, nav }} />
        </>
}

const TopicsListChip = ({ topic, nav }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const openDeleteModal = () => setDeleteModalOpen(true)
    const closeDeleteModal = () => setDeleteModalOpen(false)
    return <>
        <Card sx={{ width: "80%", maxWidth: 390, marginY: 2 }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {topic.name}
                </Typography>
            </CardContent>
            <CardActions>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                    <ButtonGroup variant="contained">
                        {/* <Button size="small" startIcon={<Create />} >Edit</Button> */}
                        <Button size="small" endIcon={<Delete />} color='error' onClick={openDeleteModal}>Delete</Button>
                    </ButtonGroup>
                    <Button size="small" variant="outlined" endIcon={<DoubleArrowOutlined />} onClick={() => nav(`${topic._id}/lectures`)}>View Topic</Button>
                </Box>
            </CardActions>
        </Card>
        <DeleteTopicModal {...{ topic, open: deleteModalOpen, handleClose: closeDeleteModal, nav }} />
    </>
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    outline: 0
};

const DeleteTopicModal = ({ topic, open, handleClose, nav }) => {
    const [inName, setInName] = useState("")
    const [isDeleting, setIsDeleting] = useState(false)
    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
                You are about to delete the topic!
            </Typography>
            <Typography sx={{ mt: 2 }}>
                To confirm deleting, please enter the topic name "{topic.name}" below.
            </Typography>
            <TextField
                placeholder={topic.name}
                variant="outlined"
                size="small"
                label="Topic Name"
                margin="dense"
                fullWidth
                sx={{ marginY: 2 }}
                value={inName}
                onChange={(e) => setInName(e.target.value)}
                error={inName !== topic.name}
                disabled={isDeleting}
            />
            <ButtonGroup fullWidth>
                <Button variant="contained" onClick={handleClose} >Cancel</Button>
                <Button variant="contained"
                    color="error"
                    endIcon={<Delete />}
                    disabled={(inName !== topic.name) || isDeleting}
                    onClick={() => {
                        deleteTopic(topic._id, setIsDeleting, handleClose, nav)
                    }}
                >
                    {isDeleting ? <Loader /> : "Delete"}
                </Button>
            </ButtonGroup>
        </Box>
    </Modal>
}

const NewTopicModal = ({ course, open, handleClose, nav }) => {
    const [topicName, setTopicName] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        topicName.length && addTopic(course, topicName, handleClose, nav, setLoading)
    }

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={modalStyle}>
            <TextField
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                label="Topic Name"
                margin='normal'
                fullWidth
            />
            <Button variant="contained" fullWidth onClick={handleSubmit} disabled={!topicName.length || loading}>{loading ? <Loader /> : "Add Topic"}</Button>
        </Box>
    </Modal>
}

export default TopicsList
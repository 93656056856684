import Axios from "axios";
import Cookies from "js-cookie";
import { setupCache } from 'axios-cache-interceptor';

import { toasts } from "./toastControllers";

import { HOST } from "./constants";
let axios_cached = setupCache(Axios.create({ baseURL: HOST }), { ttl: 30 * 1000 });

const axios = () => {
    axios_cached.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get("accessToken")}`
    return axios_cached
}

export const getAllUsers = async (setUsers, setLoading) => {
    axios().get("/admin/users").then(({ data }) => {
        setUsers(data.users)
        setLoading(false)
    })
}

export const getUserById = async (id, setUser, setCourses, setAllCourses, setLoading) => {
    await axios().get(`/admin/users/${id}`)
        .then(({ data }) => {
            setUser(data.user)
            setCourses(data.user.availableCourses)
        })
    await axios()
        .get("/admin/courses")
        .then(({ data }) => {
            setLoading(false)
            setAllCourses(data.courses);
        });
}

export const newUser = async (data, setLoading, handleClose, nav) => {
    setLoading(true)
    axios().post(`/admin/users`, data).then(({ data }) => {
        setLoading(false)
        toasts.success("New user added successfully")
        handleClose()
        setTimeout(() => nav(0), 500)
    }).catch((err) => {
        toasts.error(err?.response?.data?.message || "Something went wrong")
        setLoading(false)
        handleClose()
    })
}

export const disableUser = async (id, name, setLoading, handleClose, setUsers) => {
    setLoading(true)
    axios().put(`/admin/users/${id}`, { active: false })
        .then(() => {
            toasts.success(`Deactivated ${name}'s account`)
            setLoading(false)
            setUsers(prev => {
                return prev.map(user => {
                    return user._id === id ? { ...user, active: false } : user
                })
            })
            handleClose()
        })
        .catch(err => {
            toasts.error(err?.response?.data?.message || "Something went wrong")
            setLoading(false)
            handleClose()
        })
}

export const enableUser = async (id, name, setLoading, handleClose, setUsers) => {
    setLoading(true)
    axios().put(`/admin/users/${id}`, { active: true })
        .then(() => {
            toasts.success(`Activated ${name}'s account`)
            setLoading(false)
            setUsers(prev => {
                return prev.map(user => {
                    return user._id === id ? { ...user, active: true } : user
                })
            })
            handleClose()
        })
        .catch(err => {
            toasts.error(err?.response?.data?.message || "Something went wrong")
            setLoading(false)
            handleClose()
        })
}

export const updateCourses = async (id, courses, nav, setLoading) => {
    setLoading(true)
    var courseList = courses.filter(course => course.activeForUser)
    courseList = courseList.map(course => course._id)
    axios().put(`/admin/users/${id}`, { availableCourses: courseList })
        .then(({ data }) => {
            setLoading(false)
            toasts.success("Courses updated")
            nav(-1)
        })
        .catch((err) => {
            setLoading(false)
            toasts.error(err?.response?.data?.message || "Something went wrong")
        })
}

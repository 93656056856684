import React from 'react'

import { Routes, Route } from 'react-router-dom'
import CoursesList from '../components_/CoursesList'
import Topics from './Topics'

const Courses = () => {
    return (
        <Routes>
            <Route path="/" element={<CoursesList />} />
            <Route path="/:courseId/*" element={<Topics />} />
        </Routes>
    )
}

export default Courses
import React from 'react'
import { useParams, Routes, Route } from 'react-router-dom'
import TopicsList from '../components_/TopicsList'
import Lectures from './Lectures'

const Topics = () => {
    const { courseId } = useParams()
    return (
        <Routes>
            <Route path="/topics" element={<TopicsList course={courseId} />} />
            <Route path="/topics/:topicId/*" element={<Lectures />} />
        </Routes>
    )
}

export default Topics
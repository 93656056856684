import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'

const Landing = () => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={2}>
            <Box display="flex" flexDirection="column" width="60%">
                <Link to="users">
                    <Button fullWidth variant="contained" sx={{ marginBottom: 2 }}>User Actions</Button>
                </Link>
                <Link to="courses">
                    <Button fullWidth variant="contained" >Course Actions</Button>
                </Link>
            </Box>
        </Box>
    )
}

export default Landing
import React from 'react'
import { Routes, Route } from "react-router-dom";

import Landing from '../components_/Landing'
import Navbar from '../components_/Navbar'
import Users from './Users';
import Courses from './Courses';

const AdminHome = () => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path="/users/*" element={<Users />} />
                <Route path="/courses/*" element={<Courses />} />
            </Routes>
        </>
    )
}

export default AdminHome
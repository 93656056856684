import Axios from "axios";
import Cookies from "js-cookie";
import { setupCache } from 'axios-cache-interceptor';

import { toasts } from "./toastControllers";
import { HOST } from "./constants";
let axios_cached = setupCache(Axios.create({ baseURL: HOST }), { ttl: 30 * 1000 });

const axios = () => {
  axios_cached.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get("accessToken")}`
  return axios_cached
}

const getAllCourses = async (setCourses, setLoading) => {
  axios()
    .get("/admin/courses")
    .then(({ data }) => {
      setLoading(false)
      setCourses(data.courses);
    })
    .catch(() => toasts.error("Something went wrong"))
};

const deleteCourse = async (id, setLoading, handleClose, nav) => {
  axios().delete(`/admin/courses/${id}`)
    .then(() => {
      setLoading(false)
      toasts.success("Course deleted")
      handleClose()
      nav(0)
    })
    .catch((err) => {
      setLoading(false)
      toasts.error(err?.response?.data?.message || "Something went wrong")
    })

}

const addCourse = async (name, image, handleClose, nav, setLoading) => {
  setLoading(true)
  var fileUrl = (await axios().get("/admin/generatePresignedUploadUrl?fileType=images")).data.url
  await Axios.put(fileUrl, image).then(({ data }) => console.log(data))
  const urlObj = new URL(fileUrl);
  urlObj.search = '';
  fileUrl = urlObj.toString();

  axios().post("/admin/courses", { courseName: name, image: fileUrl })
    .then(({ data }) => {
      setLoading(false)
      toasts.success(`Course "${name}" created successfully`)
      handleClose()
      nav(0)
    })
    .catch((err) => {
      setLoading(false)
      toasts.error(err?.response?.data?.message || "Something went wrong")
    })
}

const addCourseDrive = async (name, image, handleClose, nav, setLoading) => {
  axios().post("/admin/courses", { courseName: name, image })
    .then(({ data }) => {
      setLoading(false)
      toasts.success(`Course "${name}" created successfully`)
      handleClose()
      nav(0)
    })
    .catch((err) => {
      setLoading(false)
      toasts.error(err?.response?.data?.message || "Something went wrong")
    })
}

const getAllTopics = async (course, setTopics, setLoading) => {
  axios().get(`/admin/topics/${course}`).then(({ data }) => {
    setLoading(false)
    setTopics(data.topics);
  })
    .catch(() => toasts.error("Something went wrong"))
}

const deleteTopic = async (id, setLoading, handleClose, nav) => {
  axios().delete(`/admin/topics/topic/${id}`)
    .then(({ data }) => {
      setLoading(false)
      toasts.success(`Topic deleted`)
      handleClose()
      nav(0)
    })
    .catch((err) => {
      setLoading(false)
      toasts.error(err?.response?.data?.message || "Something went wrong")
    })
}

const addTopic = async (course, name, handleClose, nav, setLoading) => {
  setLoading(true)
  axios().post(`/admin/topics/${course}`, { name })
    .then(({ data }) => {
      setLoading(false)
      toasts.success(`Topic "${name}" added successfully`)
      handleClose()
      nav(0)
    })
    .catch((err) => {
      setLoading(false)
      toasts.error(err?.response?.data?.message || "Something went wrong")
    })
}

const getAllLectures = async (course, setLectures, setLoading) => {
  axios().get(`/admin/lectures/${course}`).then(({ data }) => {
    setLoading(false)
    setLectures(data.lectures);
  })
    .catch(() => toasts.error("Something went wrong"))
}

const deleteLecture = async (id, setLoading, handleClose, nav) => {
  axios().delete(`/admin/lectures/lecture/${id}`)
    .then(({ data }) => {
      setLoading(false)
      toasts.success(`Lecture deleted`)
      handleClose()
      nav(0)
    })
    .catch((err) => {
      setLoading(false)
      toasts.error(err?.response?.data?.message || "Something went wrong")
    })
}

const addLecture = async (topic, name, video, notes, handleClose, nav, setLoading) => {
  setLoading(true)


  var videoUrl = (await Axios.get(HOST + "/admin/generatePresignedUploadUrl?fileType=video", { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } })).data.url
  await Axios.put(videoUrl, video)
  var urlObj = new URL(videoUrl);
  urlObj.search = '';
  videoUrl = urlObj.toString();


  var notesUrl = (await Axios.get(HOST + "/admin/generatePresignedUploadUrl?fileType=notes", { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } })).data.url
  await Axios.put(notesUrl, notes, { headers: { "Content-Type": "application/pdf" } })
  urlObj = new URL(notesUrl);
  urlObj.search = '';
  notesUrl = urlObj.toString();

  axios().post(`admin/lectures/${topic}`, { name, video: videoUrl, notes: notesUrl }).then(({ data }) => {
    setLoading(false)
    handleClose()
    nav(0)
  })
}

const addLectureDrive = async (topic, name, video, notes, handleClose, nav, setLoading) => {
  setLoading(true)

  axios().post(`admin/lectures/${topic}`, { name, video, notes })
    .then(({ data }) => {
      setLoading(false)
      toasts.success(`Lecture "${name}" added successfully`)
      handleClose()
      nav(0)
    })
    .catch((err) => {
      setLoading(false)
      toasts.error(err?.response?.data?.message || "Something went wrong")
    })
}

const getLectureById = async (id, setLecture) => {
  axios().get(`/admin/lectures/lecture/${id}`).then(({ data }) => {
    setLecture(data.lecture)
  })
    .catch(() => toasts.error("Something went wrong"))
}

export {
  getAllCourses, deleteCourse, addCourse, addCourseDrive,
  getAllTopics, deleteTopic, addTopic,
  getAllLectures, deleteLecture, getLectureById, addLecture, addLectureDrive
};

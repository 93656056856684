import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';

import { } from "@mui/material";
import { Box, ButtonGroup, TextField, CircularProgress as Loader } from '@mui/material';

import { DoubleArrowOutlined, Delete, Add } from '@mui/icons-material'

import { useNavigate } from 'react-router-dom';

import { addCourseDrive, deleteCourse, getAllCourses } from '../controllers/education';

const CoursesList = () => {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true);

    const [newCourseModalOpen, setNewCourseModalOpen] = useState(false);
    const openNewCourseModal = () => setNewCourseModalOpen(true)
    const closeNewCourseModal = () => setNewCourseModalOpen(false)

    const nav = useNavigate()
    useEffect(() => {
        getAllCourses(setCourses, setLoading)
    }, [])
    return loading ? <Loader /> :
        <>
            {courses.length === 0 ?
                <Typography variant="body2">No courses created yet. Click on the below button to create a course.</Typography>
                :
                <Box display="flex" flexDirection="column" alignItems="center" width="100%"> {courses.map(course => <CoursesListChip key={course._id} course={course} nav={nav} />)}</Box>}
            <Fab variant="extended" sx={{ position: 'fixed', right: 20, bottom: 20 }} color='success' onClick={openNewCourseModal}>
                <Add sx={{ mr: 1 }} />
                New Course
            </Fab>
            <NewCourseModal {...{ open: newCourseModalOpen, handleClose: closeNewCourseModal, nav }} />
        </>
}

const CoursesListChip = ({ course, nav }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const openDeleteModal = () => setDeleteModalOpen(true)
    const closeDeleteModal = () => setDeleteModalOpen(false)

    return <>
        <Card sx={{ width: "80%", maxWidth: 390, marginY: 2 }} >
            <CardMedia
                component="img"
                alt={course.name}
                height="140"
                image={course.image}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {course.name}
                </Typography>
            </CardContent>
            <CardActions>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                    <ButtonGroup variant="contained">
                        {/* <Button size="small" startIcon={<Create />} >Edit</Button> */}
                        <Button size="small" endIcon={<Delete />} color='error' onClick={openDeleteModal}>Delete</Button>
                    </ButtonGroup>
                    <Button size="small" variant="outlined" endIcon={<DoubleArrowOutlined />} onClick={() => nav(`${course._id}/topics`)}>View Course</Button>
                </Box>
            </CardActions>
        </Card>
        <DeleteCourseModal {...{ course, open: deleteModalOpen, handleClose: closeDeleteModal, nav }} />
    </>
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    outline: 0
};

const DeleteCourseModal = ({ course, open, handleClose, nav }) => {
    const [inName, setInName] = useState("")
    const [isDeleting, setIsDeleting] = useState(false)
    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
                You are about to delete the course?
            </Typography>
            <Typography sx={{ mt: 2 }}>
                To confirm deleting, please enter the course name "{course.name}" below.
            </Typography>
            <TextField
                placeholder={course.name}
                variant="outlined"
                size='small'
                label="Course Name"
                margin="dense"
                fullWidth
                sx={{ marginY: 2 }}
                value={inName}
                onChange={(e) => setInName(e.target.value)}
                error={inName !== course.name}
                disabled={isDeleting}
            />
            <ButtonGroup fullWidth>
                <Button variant="contained" onClick={handleClose} >Cancel</Button>
                <Button variant="contained"
                    color="error"
                    endIcon={<Delete />}
                    disabled={(inName !== course.name) || isDeleting}
                    onClick={() => {
                        deleteCourse(course._id, setIsDeleting, handleClose, nav)
                    }}
                >
                    {isDeleting ? <Loader /> : "Delete"}
                </Button>
            </ButtonGroup>
        </Box>
    </Modal>
}

const NewCourseModal = ({ open, handleClose, nav }) => {
    const [courseName, setCourseName] = useState("")
    const [courseImage, setCourseImage] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        courseName.length && courseImage && addCourseDrive(courseName, courseImage, handleClose, nav, setLoading)
    }

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={modalStyle}>
            <TextField
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                label="Course Name"
                margin='dense'
                fullWidth
            />
            <TextField
                value={courseImage}
                onChange={(e) => setCourseImage(e.target.value)}
                label="Course Image Drive URL"
                margin='normal'
                fullWidth
            />
            <Button variant="contained" fullWidth onClick={handleSubmit} disabled={!(!!courseName.length && !!courseImage) || loading}>{loading ? <Loader /> : "Add Course"}</Button>
        </Box>
    </Modal>
}

export default CoursesList
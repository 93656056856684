import React from 'react'

import { Routes, Route } from 'react-router-dom'
import AllUsers from '../components_/UserComponents/AllUsers'
import User from '../components_/UserComponents/User'

const Users = () => {
    return (
        <Routes>
            <Route path="/" element={<AllUsers />} />
            <Route path="/:userId" element={<User />} />
        </Routes>
    )
}

export default Users